import {get, post} from '../Api';

export const fetchMyProfile = () => {
    return get('/api/profile/me');
};

export const fetchMyWallet = () => {
    return get('/api/profile/wallet')
}

export const updateWallet = (data) => {
    return post('/api/profile/wallet/update', data)
}