import React, {memo, useContext, useEffect, useRef, useState} from 'react';
import ForceGraph2D from "react-force-graph-2d"
import {searchPerson, searchTag} from "../../api/connection/ConnectionApi";
import searchTipImage from "../../assets/search_tip_long.png"
import demoVideo from "../../assets/demo_video.mov";
import {
    arrow_right_icon,
    chain_search_icon,
    clear_icon,
    cyberConnect_icon,
    debug_icon,
    ethereum_icon,
    farcaster_icon,
    finger_icon,
    leader_board_icon,
    lens_icon,
    light_bulb_icon,
    link_icon,
    person_icon_1,
    person_icon_2,
    search_icon,
    telegram_icon,
    twitter_icon,
    twitter_x_icon
} from "../../icon/icon";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import {UserContext} from "../../context/UserProvider";
import {updateWallet} from "../../api/profile/Profile";
import {fetchTwitterAuthRedirectURL, twitterLogin} from "../../api/auth/AuthApi";
import {sourceNotRecorded, targetNotRecorded} from "../../api/feedback/FeedbackApi";
import {Link, useLocation} from "react-router-dom";
import {logo_icon} from "../../icon/logo";

const EngineSection = () => {
    const {isLoggedIn, isBindWallet} = useContext(UserContext);
    const [keyword, setKeyword] = useState('');
    const [fixedKeyword, setFixedKeyword] = useState('');
    const [result, setResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [sourceNotFound, setSourceNotFound] = useState(false)
    const [targetNotFound, setTargetNotFound] = useState(false)
    const [nodeHovered, setNodeHovered] = useState(null);
    const [linkHovered, setLinkHovered] = useState(null);
    const [unknownError, setUnknownError] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [searchBarTop, setSearchBarTop] = useState(false);

    const {login} = useContext(UserContext);

    const location = useLocation();

    let requestCounter = 0;

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');

        if (requestCounter === 0 && code) {
            ++requestCounter;
            twitterLogin({code}).then(
                response => {
                    if (response.success) {
                        login(response.data)
                    } else {
                        console.log("登录失败")
                    }
                    const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
                    window.history.pushState({path: newUrl}, '', newUrl);
                    window.location.reload();
                }
            )
        }
    }, [location.search]);

    const handleToggleVideo = () => {
        setShowVideo(!showVideo);
    }

    const handleSearch = () => {
        if (!isLoggedIn()) {
            return
        }
        setSearchBarTop(true);
        const trimmedKeyword = keyword.trim()
        setKeyword(trimmedKeyword)
        setIsLoading(true)
        setNodeHovered(null)
        setLinkHovered(null)
        setSourceNotFound(false)
        setTargetNotFound(false)
        setResult(null)
        setUnknownError(false)
        if (trimmedKeyword !== '') {
            setFixedKeyword(trimmedKeyword)
            if (trimmedKeyword.includes(" ")) {
                const param = {
                    text: trimmedKeyword
                }
                searchTag(param)
                    .then(response => {
                        if (response.success) {
                            setTargetNotFound(false)
                            setSourceNotFound(false)
                        } else if (response.errCode === 400) {
                            if (response.errMsg.includes("source")) {
                                setSourceNotFound(true)
                            } else if (response.errMsg.includes("target")) {
                                setTargetNotFound(true)
                            }
                        } else {
                            setUnknownError(true)
                        }
                        setResult(response)
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            } else {
                const param = {
                    to: trimmedKeyword
                }
                searchPerson(param)
                    .then(response => {
                        if (response.success) {
                            setTargetNotFound(false)
                            setSourceNotFound(false)
                        } else if (response.errCode === 400) {
                            if (response.errMsg.includes("source")) {
                                setSourceNotFound(true)
                            } else if (response.errMsg.includes("target")) {
                                setTargetNotFound(true)
                            }
                        } else {
                            setUnknownError(true)
                        }
                        setResult(response)
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            }
        }
    }

    const renderMain = () => {
        if (isLoading) {
            return <LoadingAnimation/>
        }
        if (!isLoggedIn()) {
            return <LoginPrompt/>
        }
        if (!isBindWallet()) {
            return <AddWalletPrompt/>
        }

        if (sourceNotFound) {
            return <SourceNotFound/>
        } else if (targetNotFound) {
            return <TargetNotFound keyword={fixedKeyword}/>
        } else if (result) {
            return <GraphCanvas result={result} setNodeHovered={setNodeHovered} setLinkHovered={setLinkHovered}/>
        } else if (unknownError) {
            return <UnknownError/>
        } else {
            return <StartPage/>
        }
    }

    return (
        <>
            <section className="h-screen flex flex-col md:flex-row items-center justify-center">

                {isLoggedIn() && isBindWallet() &&
                    <SearchBar keyword={keyword} setKeyword={setKeyword} handleSearch={handleSearch}
                               searchBarTop={searchBarTop}/>
                }

                <div>
                    <div
                        className="md:fixed md:top-14 md:left-10 z-50 md:max-h-1/3 flex items-center justify-center p-5">
                        <div className="flex items-center">
                            contact us:
                            <Link to="https://twitter.com/PondDiscoveries" target="_blank">
                                {twitter_icon("h-5 w-5 m-1")}
                            </Link>
                            <Link to="https://t.me/+vh3aTGznAPxlMGE9" target="_blank">
                                {telegram_icon("h-5 w-5 m-1")}
                            </Link>
                        </div>
                    </div>
                </div>

                <button
                    className="
                        z-20 px-4 h-10 text-gray-100 text-sm bg-orange-400 hover:bg-orange-500 focus:outline-none rounded-full shadow-2xl hidden md:block fixed right-10 top-20"
                    onClick={handleToggleVideo}>
                    Demo Video
                </button>

                <div className="h-3/4 md:h-full z-0">
                    {renderMain()}
                    <VideoModal showVideo={showVideo} handleToggleVideo={handleToggleVideo}/>
                </div>
                {!showVideo && <div className="flex p-2">
                    <NodePanel nodeHovered={nodeHovered}/>
                    <LinkPanel linkHovered={linkHovered} setNodeHovered={setNodeHovered}/>
                </div>}
            </section>
            {!showVideo && <AllPathPanel keyword={fixedKeyword} result={result} setNodeHovered={setNodeHovered}/>}
            {!showVideo && <DataSourceOverview/>}
        </>
    );
};


const LoginPrompt = () => {
    const redirectToTwitterAuthPage = () => {
        fetchTwitterAuthRedirectURL()
            .then(response => {
                if (response.success && response.data && response.data.url) {
                    window.location.href = response.data.url
                }
            })
    }

    return <div>
        <NewsPanel/>
        <div className="bg-gray-100 p-8 rounded-md flex flex-col items-center mt-20 md:mt-40">
            <h2 className="text-xl font-semibold mb-4">Please Log In</h2>
            <p className="text-gray-600 mb-4 text-center">You need to log in to continue using our services.</p>
            <button
                className="flex items-center mr-3 bg-gray-500 hover:bg-gray-800 text-white font-semibold py-2 px-4 rounded"
                onClick={redirectToTwitterAuthPage}
            >
                {twitter_icon("h-5 w-5 mr-1")} Login
            </button>
            <p className="text-gray-600 mt-4 mb-4 text-center w-1/3">The reason we require Twitter authentication is to
                verify that you
                are a genuine user and not a bot. This authentication process also helps us locate you and provide user
                search services. The auth settings are Twitter's default API setting. We won't use or control any of
                your
                Twitter data.</p>

        </div>
    </div>
}

const AddWalletPrompt = () => {
    const inputRef = useRef(null);

    const addressRegex = /^0x[0-9a-fA-F]{40}$/;

    const [walletAddress, setWalletAddress] = useState('');

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleAddWallet();
        }
    };

    const handleAddWallet = () => {
        if (!walletAddress) {
            alert("Please enter the correct ethereum wallet address!");
        }
        if (!walletAddress.startsWith("0x")) {
            setWalletAddress("0x" + walletAddress);
        }
        if (!addressRegex.test(walletAddress)) {
            alert("Please enter the correct ethereum wallet address!");
            return
        }
        updateWallet({
            address: walletAddress
        }).then(
            response => {
                if (response && response.success) {
                    window.history.pushState({path: '/engine'}, '', "/engine");
                    window.location.reload()
                } else {
                    alert("Encounter some errors!")
                }
            }
        )
    }

    const handleCleanInput = () => {
        setWalletAddress("")
        inputRef.current.focus()
    }

    const handleWalletChange = (e) => {
        setWalletAddress(e.target.value)
    }

    return (
        <div className="bg-gray-100 p-8 rounded-md flex flex-col items-center mt-20 md:mt-40">
            <h2 className="text-xl font-semibold mb-4">Last step before searching</h2>
            <p className="text-gray-600 mb-4">Enter your ethereum wallet address and save</p>
            <div className="flex space-x-1">
                <div className="relative w-full">
                    <input
                        type="text"
                        className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-full focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40 shadow-2xl"
                        placeholder="ethereum wallet address"
                        value={walletAddress}
                        onKeyDown={handleKeyDown}
                        ref={inputRef}
                        onChange={handleWalletChange}
                    />
                    {walletAddress !== '' ?
                        clear_icon(handleCleanInput) : <div></div>
                    }
                </div>
                <button className="px-4 text-white bg-gray-600 hover:bg-gray-900 rounded-full shadow-2xl"
                        onClick={handleAddWallet}>
                    save
                </button>
            </div>
        </div>
    )
}

const DataSourceOverview = () => {
    return (
        <div className="hidden md:block">
            <div
                className="fixed bottom-10 right-10 z-10 max-h-1/3 border-2 border-gray-300 rounded-2xl shadow-2xl flex items-center justify-center">
                <div className="text-gray-800 text-center p-3">
                    <h1 className="text-sm font-bold mb-2">Archived Users Number</h1>
                    <div className="flex content-center text-xs">
                        {light_bulb_icon("h-7", "#ff0000")}
                        <p className="font-bold">Twitter</p>
                        <p className="px-1 font-thin">362866(paused, Elon changed API setting)</p>
                    </div>
                    <div className="flex content-center text-xs">
                        {light_bulb_icon("h-7", "#039f03")}
                        <p className="font-bold">Lens</p>
                        <p className="px-1">117702(increasing)</p>
                    </div>
                    <div className="flex content-center text-xs">
                        {light_bulb_icon("h-7", "#039f03")}
                        <p className="font-bold">CyberConnect</p>
                        <p className="px-1">174078(increasing)</p>
                    </div>
                    <div className="flex content-center text-xs">
                        {light_bulb_icon("h-7", "#039f03")}
                        <p className="font-bold">Farcaster</p>
                        <p className="px-1">17663(increasing)</p>
                    </div>
                    <div className="flex content-center text-xs">
                        {light_bulb_icon("h-7", "#039f03")}
                        <p className="font-bold">ENS</p>
                        <p className="px-1">179943(increasing)</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const GraphCanvas = memo(({result, setNodeHovered, setLinkHovered}) => {
    if (result && result.data) {
        const start = new Set(result.data.start);
        const end = new Set(result.data.end);
        const onNodeHover = (node, prevNode) => {
            if (node) {
                setNodeHovered(node);
            }
        };

        const onLinkHover = (link, prevLink) => {
            if (link) {
                setLinkHovered(link);
            }
        }

        const nodeLabel = (node) => {
            let text = '';
            if (node && node.meta && node.meta.identity) {
                const identity = node.meta.identity;
                if (identity.twitter && identity.twitter.length > 0) {
                    text = identity.twitter[0].name;
                } else if (identity.lens && identity.lens.length > 0) {
                    text = identity.lens[0].name;
                    if (text == null || text === "") {
                        text = identity.lens[0].handle
                    }
                } else if (identity.cyberConnect && identity.cyberConnect.length > 0) {
                    text = identity.cyberConnect[0].displayName;
                } else if (identity.farcaster && identity.farcaster.length > 0) {
                    text = identity.farcaster[0].displayName;
                } else if (identity.evmAddress && identity.evmAddress.length > 0) {
                    if (identity.evmAddress[0].ens) {
                        text = identity.evmAddress[0].ens;
                    } else {
                        text = identity.evmAddress[0].address;
                    }
                }
            }
            return '<b>' + text + '</b>'
        }

        const nodeAutoColorBy = (node) => {
            if (start.has(node.id)) {
                return '1';
            } else if (end.has(node.id)) {
                return '2';
            } else {
                return '3';
            }
        }

        const linkWidth = (link) => {
            return link.value * 15
        }

        return (
            <ForceGraph2D
                graphData={result.data}
                nodeAutoColorBy={nodeAutoColorBy}
                nodeLabel={nodeLabel}
                linkDirectionalParticles={1}
                linkDirectionalParticleSpeed={d => d.value / 50}
                onNodeHover={onNodeHover}
                onLinkHover={onLinkHover}
                linkWidth={linkWidth}
                minZoom={4}
                maxZoom={6}
            />
        );
    }
});

const NodePanel = memo(({nodeHovered}) => {
    const renderTwitter = () => {
        if (nodeHovered.meta.identity.twitter) {
            return (
                <div>
                    {nodeHovered.meta.identity.twitter.map(t =>
                        <p className="flex items-center" key={'t_' + t.id}>
                            {twitter_icon("w-5 h-5")}
                            <a
                                href={"https://twitter.com/" + t.username}
                                target="_blank"
                                className="ml-2"
                            >
                                {t.name}
                            </a>
                        </p>
                    )}
                </div>)
        }
    }

    const renderLens = () => {
        if (nodeHovered.meta.identity.lens) {
            return (
                <div>
                    {nodeHovered.meta.identity.lens.map(l =>
                        <p className="flex items-center" key={'l_' + l.id}>
                            {lens_icon("w-5 h-5")}
                            <a
                                href={"https://lensport.io/" + l.handle}
                                target="_blank"
                                className="ml-2"
                            >
                                {l.name ? l.name : l.handle}
                            </a>
                        </p>
                    )}
                </div>)
        }
    }

    const renderCyberConnect = () => {
        if (nodeHovered.meta.identity.cyberConnect) {
            return (
                <div>
                    {nodeHovered.meta.identity.cyberConnect.map(c =>
                        <p className="flex items-center" key={'c_' + c.id}>
                            {cyberConnect_icon("w-5 h-5")}
                            <a
                                href={"https://link3.to/" + c.handle}
                                target="_blank"
                                className="ml-2"
                            >
                                {c.displayName}
                            </a>
                        </p>
                    )}
                </div>)
        }
    }

    const renderFarcaster = () => {
        if (nodeHovered.meta.identity.farcaster) {
            return (
                <div>
                    {nodeHovered.meta.identity.farcaster.map(f =>
                        <p className="flex items-center" key={'f_' + f.id}>
                            {farcaster_icon("w-5 h-5")}
                            <a
                                href={"https://warpcast.com/" + f.username}
                                target="_blank"
                                className="ml-2"
                            >
                                {f.displayName}
                            </a>
                        </p>
                    )}
                </div>)
        }
    }

    const renderEvmWallet = () => {
        if (nodeHovered.meta.identity.evmAddress) {
            return (
                <div>
                    {nodeHovered.meta.identity.evmAddress.map(a =>
                        <p className="flex items-center" key={'a_' + a.address}>
                            {ethereum_icon("w-5 h-5")}
                            <a
                                href={"https://etherscan.io/address/" + a.address}
                                target="_blank"
                                className="ml-2"
                            >
                                {a.ens ? a.ens : a.address}
                            </a>
                        </p>
                    )}
                </div>)
        }
    }

    if (nodeHovered) {
        return (
            <div>
                <div
                    className="relative md:fixed md:bottom-10 md:left-10 z-50 md:max-h-1/3 border-2 border-gray-300 rounded-2xl shadow-2xl flex items-center justify-center">
                    <div className="text-gray-800 text-center p-5">
                        <h1 className="text-base font-bold mb-2">Node info</h1>
                        {renderTwitter()}
                        {renderLens()}
                        {renderCyberConnect()}
                        {renderFarcaster()}
                        {renderEvmWallet()}
                    </div>
                </div>
            </div>
        );
    }
});

const LinkPanel = memo(({linkHovered, setNodeHovered}) => {
    if (linkHovered) {
        const parseBriefNode = (node) => {
            let text = '';
            let icon = null;
            if (node && node.meta && node.meta.identity) {
                const identity = node.meta.identity;
                if (identity.twitter && identity.twitter.length > 0) {
                    text = identity.twitter[0].name;
                    icon = twitter_icon("w-5 h-5");
                } else if (identity.lens && identity.lens.length > 0) {
                    text = identity.lens[0].name;
                    if (text == null || text === "") {
                        text = identity.lens[0].handle
                    }
                    icon = lens_icon("w-5 h-5");
                } else if (identity.cyberConnect && identity.cyberConnect.length > 0) {
                    text = identity.cyberConnect[0].displayName;
                    icon = cyberConnect_icon("w-5 h-5");
                } else if (identity.farcaster && identity.farcaster.length > 0) {
                    text = identity.farcaster[0].displayName;
                    icon = farcaster_icon("w-5 h-5");
                } else if (identity.evmAddress && identity.evmAddress.length > 0) {
                    icon = ethereum_icon("w-5 h-5");
                    if (identity.evmAddress[0].ens) {
                        text = identity.evmAddress[0].ens;
                    } else {
                        text = identity.evmAddress[0].address;
                    }
                }
            }
            if (icon) {
                return <p className="flex items-center" onMouseOver={() => {
                    setNodeHovered(node);
                }}>{icon}{text}</p>;
            }
        }
        return (
            <div>
                <div
                    className="md:fixed md:top-32 md:left-10 z-50 md:max-h-1/3 border-2 border-gray-300 rounded-2xl shadow-2xl flex items-center justify-center p-5">
                    <div className="text-gray-800 text-center">
                        <h1 className="text-base font-bold mb-2">Link</h1>
                        {parseBriefNode(linkHovered.source)}
                        <div className="flex justify-center">
                            {link_icon}
                        </div>
                        {parseBriefNode(linkHovered.target)}
                        <div className="flex justify-center">
                            <div className="flex items-center">
                                {leader_board_icon}
                                {(linkHovered.value * 10).toFixed(2)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});

const AllPathPanel = memo(({keyword, result, setNodeHovered}) => {
    if (result && result.success && result.data) {
        const start = new Set(result.data.start);
        const end = new Set(result.data.end);

        const nodeMap = new Map();
        result.data.nodes.forEach((node) => {
            nodeMap.set(node.id, node)
        })

        const parseBriefNode = (node) => {
            if (start.has(node.id)) {
                return person_icon_1;
            } else if (end.has(node.id)) {
                return person_icon_2;
            }
            let text = '';
            let icon = null;
            if (node && node.meta && node.meta.identity) {
                const identity = node.meta.identity;
                if (identity.twitter && identity.twitter.length > 0) {
                    text = identity.twitter[0].name;
                    icon = twitter_icon("w-3 h-3");
                } else if (identity.lens && identity.lens.length > 0) {
                    text = identity.lens[0].name;
                    if (text == null || text === "") {
                        text = identity.lens[0].handle
                    }
                    icon = lens_icon("w-3 h-3");
                } else if (identity.cyberConnect && identity.cyberConnect.length > 0) {
                    text = identity.cyberConnect[0].displayName;
                    icon = cyberConnect_icon("w-3 h-3");
                } else if (identity.farcaster && identity.farcaster.length > 0) {
                    text = identity.farcaster[0].displayName;
                    icon = farcaster_icon("w-3 h-3");
                } else if (identity.evmAddress && identity.evmAddress.length > 0) {
                    icon = ethereum_icon("w-3 h-3");
                    if (identity.evmAddress[0].ens) {
                        text = identity.evmAddress[0].ens;
                    } else {
                        text = identity.evmAddress[0].address;
                    }
                }
            }
            if (text.length >= 9) {
                text = text.substring(0, 8) + '...'
            }
            if (icon) {
                return <p className="flex items-center" onMouseOver={() => {
                    setNodeHovered(nodeMap.get(node.id));
                }}>{icon}{text}</p>;
            }
        }

        const extractPath = (path, rowCount) => {
            let appendArrow = false;
            let counter = 0;

            return (
                <tr key={'p_r_' + rowCount} className="even:bg-blue-gray-50/50">
                    <td>
                        <div className="flex items-center">
                            {leader_board_icon}
                            {(10 / path.cost).toFixed(2)}
                        </div>
                    </td>
                    <td>
                        <div className="flex items-center">
                            {
                                path.nodeIds.map(nodeId => {
                                    if (!appendArrow) {
                                        appendArrow = true;
                                        return <div key={'p_' + counter++}
                                                    className="flex items-center">{parseBriefNode(nodeMap.get(nodeId))}</div>;
                                    } else {
                                        return <div key={'p_r_' + rowCount + '_' + counter++}
                                                    className="flex items-center">
                                            {arrow_right_icon}
                                            {parseBriefNode(nodeMap.get(nodeId))}
                                        </div>;
                                    }
                                })
                            }
                        </div>
                    </td>
                </tr>
            );
        }

        let rowCount = 0;
        return (
            <div
                className="md:fixed md:top-32 md:right-10 z-0 md:z-20 max-w-4xl border-2 border-gray-300 rounded-2xl shadow-2xl flex items-center justify-center p-5 mx-2 md:mx-0">
                <div className="text-gray-800 text-center">
                    <h1 className="text-lg font-bold mb-2">search result for:</h1>
                    <h1 className="text-sm font-bold mb-2">{keyword}</h1>
                    <div className="table-container max-h-96 overflow-y-scroll">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                            <tr>
                                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                    Score
                                </th>
                                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                    Path
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {result.data.paths.map((path) => {
                                return extractPath(path, rowCount++);
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        );
    }
});


const SearchBar = memo(({
                            keyword, setKeyword, handleSearch, searchBarTop
                        }) => {
    const inputRef = useRef(null);

    const {isLoggedIn} = useContext(UserContext);

    const onKeywordChange = (event) => {
        setKeyword(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (isLoggedIn()) {
            if (event.key === 'Enter') {
                handleSearch();
            }
        }
    };

    const handleCleanInput = () => {
        setKeyword('');
        inputRef.current.focus()
    }

    return (
        <div className={"fixed z-50 w-3/4 md:w-1/3 " + (searchBarTop ? "top-20" : "top-1/4")}>
            <div className="flex space-x-1">
                <div className="relative w-full">
                    <input
                        type="text"
                        className="block w-full px-4 py-2 text-gray-700 bg-white border-4 rounded-full border-gray-400 focus:border-gray-600 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40 shadow-2xl"
                        placeholder={isLoggedIn() ? "Search ens/twitter/cyberConnect/lens/farcaster handle" : "Please login before searching"}
                        value={keyword}
                        onChange={onKeywordChange}
                        onKeyDown={handleKeyDown}
                        ref={inputRef}
                        disabled={!isLoggedIn()}
                    />
                    {keyword !== '' ?
                        clear_icon(handleCleanInput) : <div></div>
                    }
                </div>
                <button className="px-4 text-white bg-gray-600 hover:bg-gray-900 rounded-full shadow-2xl"
                        onClick={handleSearch} disabled={!isLoggedIn()}>
                    {search_icon}
                </button>
            </div>
        </div>
    );
});

const VideoModal = ({showVideo, handleToggleVideo}) => {
    return (<>
        {showVideo &&
            <div class="relative z-50" aria-labelledby="video-modal" aria-modal="true">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                <div class="fixed inset-0 z-50 overflow-y-auto">
                    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <div
                            class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:mt-28 sm:w-full sm:max-w-5xl">
                            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <video controls="controls" width="1200" height="900" name="Demo Video">
                                    <source src={demoVideo}/>
                                </video>
                            </div>
                            <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button type="button" onClick={handleToggleVideo}
                                        class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto">Got
                                    it!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>)
}

const NewsPanel = () => {
    return <div className="text-left">
        <div
            className="md:fixed md:top-32 md:right-10 z-50 md:max-h-1/3 md:max-w-xs border-4 border-orange-300 rounded-2xl shadow-2xl flex items-center justify-center p-5 relative">
            <div
                className="absolute top-0 left-0 font-bold text-white bg-orange-300 rounded-r-xl h-8 w-1/4 flex items-center justify-center">
                <a
                    href="https://twitter.com/PondDiscoveries"
                    target="_blank">
                    {twitter_x_icon("h-8 w-8")}
                </a>
            </div>
            <div className="text-gray-800 pt-4">
                &nbsp;&nbsp;&nbsp;&nbsp; We are collaborating with
                <a href="https://www.decentralised.co/" target="_blank"><b> Decentralised.co </b></a> - A
                newsletter that curates deep and insightful
                crypto stories and thoughts trusted by readers at Coinbase, Variant, Sequoia, Dune, and many
                more. <a href="https://twitter.com/PondDiscoveries/status/1709100920301314189" target="_blank"><b>Find more here</b></a>
            </div>
        </div>
    </div>
}

const StartPage = () => {
    return (
        <div className="flex items-center flex-col text-center">
            <div className="md:mt-1/12">{logo_icon("h-32 w-32 pl-1", "#f2f3f6")}</div>
            <div className="md:w-2/3 h-auto mt-20 md:mt-1/10">
                <h1 className="flex items-center justify-center mb-5">
                    Free API/application integration {finger_icon("w-5 h-5 mx-1")} Telegram: @CZDylan
                </h1>
                <NewsPanel/>

                <div className="md:hidden">
                    <div className="flex justify-center">
                        {chain_search_icon}
                        <p className="mt-6 font-bold">
                            Search Tips
                        </p>
                    </div>
                    <div className="text-left px-4">
                        <p className="mt-4">
                            1. Search with Twitter username or Twitter handle
                        </p>
                        <p className="mt-4">
                            2. Search with CyberConnect DID ending with “.cyber”
                        </p>
                        <p className="mt-4">
                            3. Search with ENS DID ending with “.eth”
                        </p>
                        <p className="mt-4">
                            4. Search with wallet address
                        </p>
                    </div>
                </div>
                <img src={searchTipImage} alt="Search Tips" className="hidden md:block"/>
            </div>
        </div>
    )
}

const TargetNotFound = (keyword) => {
    const [email, setEmail] = useState('');

    const defaultDetail = {
        keyword: '',
        twitter: '',
        lens: '',
        cyberConnect: '',
        walletAddress: '',
        ens: '',
        ext: ''
    }

    const [detail, setDetail] = useState(defaultDetail);

    const handleDetailChange = (e) => {
        setDetail({...detail, [e.target.name]: e.target.value});
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        detail.keyword = keyword.keyword
        // 处理表单提交逻辑
        const data = {
            email: email,
            detail: detail
        }
        targetNotRecorded(data)
            .then(response => {
                if (response && response.success) {
                    alert("Submit successfully!")
                    setDetail(defaultDetail)
                } else {
                    alert("Fail to submit! Sorry!")
                }
            })
    };

    return (
        <div className="px-4 md:px-0">
            <div className="flex items-center justify-center flex-col text-center mt-20 md:mt-40 mb-6">
                {debug_icon}
                <p className="mt-1 text-sm">
                    Oops! No result found for your query.
                </p>
                <p className="mt-1 text-sm">
                    Please help provide more information about this guy you try to query.
                </p>
            </div>
            <div className="w-full max-w-lg mx-auto">
                <form className="bg-gray-100 rounded pt-3" onSubmit={handleSubmit}>
                    <div>
                        <label className="block text-gray-700 text-sm font-bold " htmlFor="twitter">
                            Twitter
                        </label>
                        <input
                            className="text-sm shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="twitter"
                            type="text"
                            placeholder="twitter handle"
                            name="twitter"
                            value={detail.twitter}
                            onChange={handleDetailChange}
                        />
                    </div>

                    <div className="flex">
                        <div className="w-1/2 mr-2">
                            <label className="block text-gray-700 text-sm font-bold mt-1" htmlFor="lens">
                                Lens
                            </label>
                            <input
                                className="text-sm shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="lens"
                                type="text"
                                placeholder="lens handle"
                                name="lens"
                                value={detail.lens}
                                onChange={handleDetailChange}
                            />
                        </div>

                        <div className="w-1/2 ml-2">
                            <label className="block text-gray-700 text-sm font-bold mt-1" htmlFor="cyberConnect">
                                CyberConnect
                            </label>
                            <input
                                className="text-sm shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="cyberConnect"
                                type="text"
                                placeholder="cyberConnect handle"
                                name="cyberConnect"
                                value={detail.cyberConnect}
                                onChange={handleDetailChange}
                            />
                        </div>
                    </div>

                    <div className="flex mb-1">
                        <div className="w-1/2 mr-2">
                            <label className="block text-gray-700 text-sm font-bold mt-1" htmlFor="walletAddress">
                                Wallet Address
                            </label>
                            <input
                                className="text-sm shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="walletAddress"
                                type="text"
                                placeholder="ethereum wallet address"
                                name="walletAddress"
                                value={detail.walletAddress}
                                onChange={handleDetailChange}
                            />
                        </div>

                        <div className="w-1/2 ml-2">
                            <label className="block text-gray-700 text-sm font-bold mt-1" htmlFor="ens">
                                ENS
                            </label>
                            <input
                                className="text-sm shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="ens"
                                type="text"
                                placeholder="ENS"
                                name="ens"
                                value={detail.ens}
                                onChange={handleDetailChange}
                            />
                        </div>
                    </div>

                    <div className="mb-1">
                        <label className="block text-gray-700 text-sm font-bold mt-1" htmlFor="ext">
                            Other information
                        </label>
                        <input
                            className="text-sm shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="ext"
                            type="text"
                            placeholder="other information"
                            name="ext"
                            value={detail.ext}
                            onChange={handleDetailChange}
                        />
                    </div>

                    <div className="mb-1">
                        <label className="block text-gray-700 text-sm font-bold mt-1" htmlFor="ext">
                            Enter your email if you want us to inform you when database is updated
                        </label>
                        <input
                            className="text-sm shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email"
                            type="text"
                            placeholder="your email"
                            name="email"
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </div>

                    <div className="flex items-center justify-between mt-4">
                        <button
                            className="text-sm bg-gray-500 hover:bg-gray-700 text-white font-bold mt-1 py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                            type="submit"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const SourceNotFound = () => {
    const [email, setEmail] = useState('');

    const defaultDetail = {
        twitter: '',
        lens: '',
        cyberConnect: '',
        walletAddress: '',
        ens: '',
        ext: ''
    }

    const [detail, setDetail] = useState(defaultDetail);

    const handleDetailChange = (e) => {
        setDetail({...detail, [e.target.name]: e.target.value});
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            email: email,
            detail: detail
        }
        sourceNotRecorded(data)
            .then(response => {
                if (response && response.success) {
                    alert("Submit successfully!")
                    setDetail(defaultDetail)
                } else {
                    alert("Fail to submit! Sorry!")
                }
            })
    };

    return (
        <div className="mt-20 md:mt-40 px-4">
            <div className="flex items-center justify-center flex-col text-center">
                {debug_icon}
                <p className="mt-1 text-sm">
                    Oops! We haven't collect your data yet.
                </p>
                <p className="mt-1 text-sm">
                    If you can provide some information about yourself, we will make your data available as soon as
                    possible.
                </p>
            </div>
            <div className="w-full max-w-lg mx-auto mt-4">
                <form className="bg-gray-100 rounded pt-3" onSubmit={handleSubmit}>
                    <div>
                        <label className="block text-gray-700 text-sm font-bold " htmlFor="twitter">
                            Twitter
                        </label>
                        <input
                            className="text-sm shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="twitter"
                            type="text"
                            placeholder="your twitter handle"
                            name="twitter"
                            value={detail.twitter}
                            onChange={handleDetailChange}
                        />
                    </div>

                    <div className="flex">
                        <div className="w-1/2 mr-2">
                            <label className="block text-gray-700 text-sm font-bold mt-1" htmlFor="lens">
                                Lens
                            </label>
                            <input
                                className="text-sm shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="lens"
                                type="text"
                                placeholder="your lens handle"
                                name="lens"
                                value={detail.lens}
                                onChange={handleDetailChange}
                            />
                        </div>

                        <div className="w-1/2 ml-2">
                            <label className="block text-gray-700 text-sm font-bold mt-1" htmlFor="cyberConnect">
                                CyberConnect
                            </label>
                            <input
                                className="text-sm shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="cyberConnect"
                                type="text"
                                placeholder="your cyberConnect handle"
                                name="cyberConnect"
                                value={detail.cyberConnect}
                                onChange={handleDetailChange}
                            />
                        </div>
                    </div>

                    <div className="flex mb-1">
                        <div className="w-1/2 mr-2">
                            <label className="block text-gray-700 text-sm font-bold mt-1" htmlFor="walletAddress">
                                Wallet Address
                            </label>
                            <input
                                className="text-sm shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="walletAddress"
                                type="text"
                                placeholder="your ethereum wallet address"
                                name="walletAddress"
                                value={detail.walletAddress}
                                onChange={handleDetailChange}
                            />
                        </div>

                        <div className="w-1/2 ml-2">
                            <label className="block text-gray-700 text-sm font-bold mt-1" htmlFor="ens">
                                ENS
                            </label>
                            <input
                                className="text-sm shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="ens"
                                type="text"
                                placeholder="your ENS"
                                name="ens"
                                value={detail.ens}
                                onChange={handleDetailChange}
                            />
                        </div>
                    </div>

                    <div className="mb-1">
                        <label className="block text-gray-700 text-sm font-bold mt-1" htmlFor="ext">
                            Other information
                        </label>
                        <input
                            className="text-sm shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="ext"
                            type="text"
                            placeholder="other information"
                            name="ext"
                            value={detail.ext}
                            onChange={handleDetailChange}
                        />
                    </div>

                    <div className="mb-1">
                        <label className="block text-gray-700 text-sm font-bold mt-1" htmlFor="ext">
                            Enter your email if you want us to inform you when database is updated
                        </label>
                        <input
                            className="text-sm shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email"
                            type="text"
                            placeholder="your email"
                            name="email"
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </div>

                    <div className="flex items-center justify-between mt-4">
                        <button
                            className="text-sm bg-gray-500 hover:bg-gray-700 text-white font-bold mt-1 py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                            type="submit"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const UnknownError = () => {
    return (
        <div className="flex items-center justify-center flex-col text-center">
            {debug_icon}
            <p className="mt-4">
                Oops! Unknown error
            </p>
            <p className="mt-4">
                Report it <a>here</a>
            </p>
        </div>
    )
}

export default EngineSection;
