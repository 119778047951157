import React from 'react';

function NotFoundPage() {
    return (
        <div className="bg-gray-100 min-h-screen flex flex-col justify-center items-center">
            <div className="text-gray-900 text-center">
                <svg
                    className="mx-auto h-16 w-16 fill-current text-gray-600"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                >
                    <path
                        fillRule="evenodd"
                        d="M10 2a8 8 0 100 16 8 8 0 000-16zm1 11a1 1 0 11-2 0 1 1 0 012 0zm-.35-2.153c.417-.414.35-1.053-.135-1.316a.984.984 0 00-1.256.252 2.08 2.08 0 01-1.697 1.054 2.08 2.08 0 01-2.323-1.328c-.165-.44-.797-.44-.961 0a2.08 2.08 0 01-2.323 1.328c-.44-.165-.78.135-.616.575.417 1.102 1.49 1.84 2.759 1.84a3.648 3.648 0 001.872-.52c.477-.27 1.057-.052 1.327.425.37.52.897.845 1.5.845.603 0 1.13-.325 1.5-.845.27-.477.85-.695 1.327-.425.446.25.997.314 1.497.17a3.648 3.648 0 001.872.52c1.27 0 2.342-.738 2.76-1.84.164-.44-.176-.74-.615-.575a2.08 2.08 0 01-2.322-1.328zm-.07-4.274l-.005-.006-.005-.004a.59.59 0 00-.28-.133c-.09 0-.185.04-.255.11a.363.363 0 00-.11.255l-.006.03v1.567a.61.61 0 00.41.573l.067.014h.78a.61.61 0 00.573-.41l.014-.067V6.72a.27.27 0 00-.01-.088.363.363 0 00-.11-.256.368.368 0 00-.256-.11z"
                        clipRule="evenodd"
                    />
                </svg>
                <h1 className="mt-2 text-2xl font-bold">Page Not Found</h1>
                <p className="mt-1 text-lg">
                    The page you are looking for does not exist.
                </p>
            </div>
        </div>
    );
}

export default NotFoundPage;
