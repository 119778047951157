import React, {createContext, useEffect, useState} from 'react';
import {fetchMyProfile} from "../api/profile/Profile";

// 创建UserContext上下文
export const UserContext = createContext(null);

// 创建UserProvider组件
export const UserProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // 用户登录
    const login = (userData) => {
        setUser(userData);
    };

    // 用户注销
    const logoutUSer = () => {
        setUser(null);
    };

    // 判断用户是否已登录
    const isLoggedIn = () => {
        return user !== null;
    };

    const isBindWallet = () => {
        return user !== null && user.bindWallet;
    }

    useEffect(() => {
        fetchMyProfile()
            .then(response => {
                if (response && response.success) {
                    const userData = response.data;
                    // 设置用户状态
                    setUser(userData);
                } else {

                }
            }).finally(
            () => {
                setIsLoading(false)
            }
        )
    }, []); // 仅在组件挂载时执行一次

    // 将需要共享的值传递给子组件
    const contextValue = {
        user,
        isLoading,
        isLoggedIn,
        login,
        logoutUSer,
        isBindWallet,
    };

    // 如果正在加载中，则可以展示加载状态或其他UI
    if (isLoading) {
        return <div className="flex items-center justify-center h-screen">
            <div className="flex items-center justify-center ">
                <div className="w-24 h-24 border-l-2 border-gray-900 rounded-full animate-spin"></div>
            </div>
        </div>;
    }

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};
