import React, {useContext, useEffect} from 'react';
import imageMess from '../../assets/mess.png';
import imageGraphInPond from '../../assets/graph_in_pond.png';
import avatar1 from '../../assets/avatar_1.png';
import avatar2 from '../../assets/avatar_2.png';
import avatar3 from '../../assets/avatar_3.png';
import avatar4 from '../../assets/avatar_4.png';
import avatar5 from '../../assets/avatar_5.png';
import avatar6 from '../../assets/avatar_6.png';
import {Link, useLocation} from "react-router-dom";
import {twitterLogin} from "../../api/auth/AuthApi";
import {UserContext} from '../../context/UserProvider';
import {logo_icon} from "../../icon/logo";
import {telegram_icon, twitter_icon} from "../../icon/icon";

function HomePage() {
    const {login} = useContext(UserContext);

    const location = useLocation();

    let requestCounter = 0;

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');

        if (requestCounter === 0 && code) {
            ++requestCounter;
            twitterLogin({code}).then(
                response => {
                    if (response.success) {
                        login(response.data)
                    } else {
                        console.log("登录失败")
                    }
                    const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
                    window.history.pushState({path: newUrl}, '', newUrl);
                    window.location.reload();
                }
            )
        }
    }, [location.search]);

    return (
        <div>
            <section className="flex flex-col items-center justify-center h-screen bg-white">
                <h1 className="text-center text-lg font-bold mt-10 text-gray-700">Empowering Web3 Applications with 10x
                    Efficiency: Pond's Massive GNN Redefines On-chain Data</h1>
                <div className="flex flex-col md:flex-row justify-center md:mt-8">
                    <div className="flex flex-col items-center md:w-1/3 p-5">
                        <img src={imageMess} alt="Out of Pond" className="w-1/2 md:w-full md:h-full object-contain"/>
                        <p className="mt-2 text-center">Out of Pond</p>
                    </div>
                    <div className="flex flex-col items-center md:w-1/3 p-5">
                        <img src={imageGraphInPond} alt="In Pond" className="w-1/2 md:w-full md:h-full object-contain"/>
                        <p className="mt-2 text-center">In Pond</p>
                    </div>
                </div>
                <div>
                    <div className="flex items-center">
                        <Link to="https://twitter.com/PondDiscoveries" target="_blank">
                            {twitter_icon("h-6 w-6 m-1")}
                        </Link>
                        <Link to="https://t.me/+vh3aTGznAPxlMGE9" target="_blank">
                            {telegram_icon("h-6 w-6 m-1")}
                        </Link>
                    </div>
                </div>
            </section>
            <section className="flex flex-col items-center md:justify-center md:h-screen bg-gray-200 px-5">
                <div className="flex items-center justify-center">
                    {logo_icon("h-12 w-30", "#E5E7EB")}
                </div>
                <h1 className="text-center text-lg font-bold text-gray-700 mb-2">Plunge into Pond: Where Web3's Data Currents Flow
                    into Infinite Connections</h1>
                <div>
                    <div className="flex flex-col md:flex-row md:justify-center">
                        <div className="md:w-1/5 md:py-5 px-5 py-2">
                            <div>
                                <img src={avatar1} alt="Dylan Zhang" className="w-1/5 h-auto inline-block md:block"/>
                                <span className="my-2 font-bold text-sm">Dylan Zhang /CEO</span>
                            </div>
                            
                            <p className="text-xs mt-3">5 years of experience in business strategy and product
                                development.</p>
                            <ul className="mt-1 text-xs">
                                <li>- First employee of a SF web3 startup.</li>
                                <li>- Founder of WednesDAO, an exclusive web 3 community.</li>
                                <li>- Previously worked as a Strategic Account Manager at Huawei, overseeing IT products
                                    with central banks.
                                </li>
                            </ul>
                        </div>
                        <div className="md:w-1/5 md:py-5 px-5 py-2">
                            <div>
                                <img src={avatar2} alt="Bill Shi" className="w-1/5 h-auto inline-block md:block"/>
                                <span className="my-2 font-bold text-sm">Bill Shi /CTO</span>
                            </div>
                            <p className="text-xs mt-3">17 years of graph and algorithm experience.</p>
                            <ul className="mt-1 text-xs">
                                <li>- Senior Machine Learning Architect and Applied Scientist.</li>
                                <li>- Published over 30 papers in prestigious journals such as Nature and National
                                    Academy of Sciences.
                                </li>
                                <li>- Postdoc at University of Chicago, PhD at University of North Carolina at Chapel
                                    Hill.
                                </li>
                            </ul>
                        </div>
                        <div className="md:w-1/5 md:py-5 px-5 py-2">
                            <div>
                                <img src={avatar3} alt="Garvey Wong" className="w-1/5 h-auto inline-block md:block"/>
                                <span className="my-2 font-bold text-sm">Garvey Wong /Staff Engineer</span>
                            </div>
                            <p className="text-xs mt-3">4 years of experience in backend and blockchain development.</p>
                            <ul className="mt-1 text-xs">
                                <li>- Java full-stack engineer.</li>
                                <li>- Senior Java engineer at NetEase.</li>
                                <li>- CS Master at Peking University, CS Bachelor at Tongji University.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:justify-center">
                        <div className="md:w-1/5 md:py-5 px-5 py-2">
                            <div>
                                <img src={avatar4} alt="Marco Cheng" className="w-1/5 h-auto inline-block md:block"/>
                                <span className="my-2 font-bold text-sm">Marco Cheng /Research Lead </span>
                            </div>
                            <p className="text-xs mt-3">4 years of backend and blockchain development experience.</p>
                            <ul className="mt-1 text-xs">
                                <li>- Top 20 competitive hacker worldwide and gold medalist of Informatics Olympiad.</li>
                                <li>- A zk and zkML researcher. Papers accepted by ACM CCS.</li>
                                <li>- Summa Cum Laude from Yao Class at Tsinghua University.</li>
                            </ul>
                        </div>
                        <div className="md:w-1/5 md:py-5 px-5 py-2">
                            <div>
                                <img src={avatar5} alt="Paul Yu" className="w-1/5 h-auto inline-block md:block"/>
                                <span className="my-2 font-bold text-sm">Paul Yu /Product & Data Engineer</span>
                            </div>
                            <p className="text-xs mt-3">4 years of backend and blockchain development experience.</p>
                            <ul className="mt-1 text-xs">
                                <li>- Researcher in L2 ecosystem.</li>
                                <li>- Multiple National Competition Highest Prize.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="flex flex-col items-center justify-center h-screen bg-white">
                <div className="md:w-1/5 p-5">
                    <img src={avatar6} alt="Wayne Shi" className="w-1/2 h-auto"/>
                    <p className="mt-2 font-bold text-sm">Wayne Shi /Advisor (CTO Office)</p>
                </div>
            </section>
        </div>
    );
}

export default HomePage;