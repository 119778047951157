import React, {useState} from 'react';
import {Transition} from '@headlessui/react';
import {FiTwitter} from 'react-icons/fi';
import {fetchTwitterAuthRedirectURL} from '../../api/auth/AuthApi'

function AuthPage() {
    const [isLoginPanelVisible, setLoginPanelVisible] = useState(true);

    const togglePanel = () => {
        setLoginPanelVisible(!isLoginPanelVisible);
    };

    function redirectToTwitterAuthPage() {
        fetchTwitterAuthRedirectURL()
            .then(response => {
                if (response.success && response.data && response.data.url) {
                    window.location.href = response.data.url
                }
            })
    }

    return (
        <div className="bg-gray-100 min-h-screen flex flex-col justify-center items-center">
            <div className="bg-white w-96 p-8 rounded-lg shadow-lg">
                <div className="flex items-center justify-between mb-8">
                    <h1 className="text-2xl font-bold">
                        {isLoginPanelVisible ? 'Login' : 'Register'}
                    </h1>
                    <button
                        className="text-sm text-gray-500 hover:text-gray-700"
                        onClick={togglePanel}
                    >
                        {isLoginPanelVisible ? 'Create an account' : 'Already have an account?'}
                    </button>
                </div>
                <Transition
                    show={isLoginPanelVisible}
                    enter="transition-opacity duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div>
                        <input
                            type="text"
                            className="w-full border border-gray-300 rounded-lg py-2 px-4 mb-4"
                            placeholder="Email"
                        />
                        <input
                            type="password"
                            className="w-full border border-gray-300 rounded-lg py-2 px-4 mb-4"
                            placeholder="Password"
                        />
                        <button className="w-full bg-gray-500 hover:bg-gray-800 text-white rounded-lg py-2 px-4 mb-4">
                            Login
                        </button>
                        <p className="text-center text-gray-500">Or login with</p>
                        <div className="flex justify-center mt-2">
                            <button className="mr-2 bg-blue-400 text-white rounded-full p-2"
                                    onClick={redirectToTwitterAuthPage}>
                                <FiTwitter/>
                            </button>
                        </div>
                    </div>
                </Transition>
                <Transition
                    show={!isLoginPanelVisible}
                    enter="transition-opacity duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div>
                        <input
                            type="text"
                            className="w-full border border-gray-300 rounded-lg py-2 px-4 mb-4"
                            placeholder="Name"
                        />
                        <input
                            type="text"
                            className="w-full border border-gray-300 rounded-lg py-2 px-4 mb-4"
                            placeholder="Email"
                        />
                        <input
                            type="password"
                            className="w-full border border-gray-300 rounded-lg py-2 px-4 mb-4"
                            placeholder="Password"
                        />
                        <button className="w-full bg-gray-500 hover:bg-gray-800 text-white rounded-lg py-2 px-4 mb-4">
                            Register
                        </button>
                    </div>
                </Transition>
            </div>
        </div>
    );
}

export default AuthPage;
