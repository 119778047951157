import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HomePage from "./page/homepage/HomePage";
import EnginePage from "./page/engine/EnginePage";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import NotFoundPage from "./page/notfound/NotFoundPage";
import AuthPage from "./page/auth/AuthPage";
import {UserProvider} from './context/UserProvider';
import ProfilePage from "./page/profile/ProfilePage";

function App() {
    return (
        <UserProvider>
            <div className="bg-gray-100">
                <Router>
                    <Navbar/>
                    <div className="container-fluid mx-auto">
                        <Routes>
                            <Route path="/" element={<EnginePage/>}/>
                            <Route path="/aboutUs" element={<HomePage/>}/>
                            <Route path="/profile" element={<ProfilePage/>}/>
                            <Route path="/engine" element={<EnginePage/>}/>
                            <Route path="/auth" element={<AuthPage/>}/>
                            <Route path='*' element={<NotFoundPage/>}/>
                        </Routes>
                    </div>
                    <Footer/>
                </Router>
            </div>
        </UserProvider>
    );
}

export default App;
