import React, {useEffect, useState} from 'react';
import {fetchMyWallet, updateWallet} from "../../api/profile/Profile";

const ProfilePage = () => {
    const [walletAddress, setWalletAddress] = useState('');
    const [result, setResult] = useState('');

    const handleWalletAddressChange = (event) => {
        setWalletAddress(event.target.value);
    };

    const handleWalletAddressSave = (event) => {
        updateWallet({
            address: walletAddress
        }).then(
            response => {
                setResult(response)
                if (response && response.success) {
                    window.history.pushState({path: '/engine'}, '', "/engine");
                    window.location.reload()
                }
            }
        )
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // 在这里处理表单提交逻辑
        console.log('Submitted wallet address:', walletAddress);
    };

    useEffect(() => {
        fetchMyWallet().then(
            response => {
                if (response.success && response.data && response.data.wallets.length > 0) {
                    setWalletAddress(response.data.wallets[0].address)
                }
            }
        )
    }, [])

    return (
        <div className="container mx-auto px-4 py-8 h-screen flex items-center">
            <div className="max-w-md mx-auto bg-white shadow-md rounded-md p-6">
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="walletAddress" className="text-lg font-medium">Wallet Address</label>
                        <input
                            type="text"
                            id="walletAddress"
                            className="mt-2 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={walletAddress}
                            onChange={handleWalletAddressChange}
                            placeholder="Enter your wallet address"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-gray-500 text-white rounded-md py-2 px-4 my-2 hover:bg-gray-600 transition duration-200"
                        onClick={handleWalletAddressSave}
                    >
                        Save
                    </button>

                    {result && result.success &&
                        <div className="bg-green-100 border-t border-b border-green-500 text-green-700 px-4 py-3"
                             role="alert">
                            <p className="font-bold">Success</p>
                        </div>
                    }
                    {result && !result.success &&
                        <div className="bg-orange-100 border-t border-b border-orange-500 text-orange-700 px-4 py-3"
                             role="alert">
                            <p className="font-bold">Sorry, try again</p>
                        </div>
                    }


                </form>
            </div>
        </div>
    );
}

export default ProfilePage