export const logo_icon = (className, fill) =>
    <svg viewBox="0 0 1280 720" version="1.1" xmlns="http://www.w3.org/2000/svg"
         fill="currentColor" className={className}>
        <defs
            id="defs6">
            <clipPath
                id="clipPath20"
                clipPathUnits="userSpaceOnUse">
                <path
                    id="path18"
                    d="M 0,1.206994e-5 H 959.76 V 540.00001 H 0 Z"/>
            </clipPath>
        </defs>
        <g
            transform="matrix(1.3333333,0,0,-1.3333333,0,720)"
            id="g10">
            <g
                id="g12"/>
            <g
                id="g14">
                <g
                    clipPath="url(#clipPath20)"
                    id="g16">
                    <path
                        id="path22"
                        fill={fill}
                        style={{"fillOpacity": 1, "fillRule": "nonzero", "stroke": "none"}}
                        d="M 0,540 H 959.76 V 1.206994e-5 H 0 Z"/>
                </g>
            </g>
            <g
                id="g24">
                <path
                    id="path26"
                    fill={fill}
                    style={{"fillOpacity": 1, "fillRule": "nonzero", "stroke": "none"}}
                    d="M 0,540 H 960 V 1.40816e-5 H 0 Z"/>
                <path
                    id="path28"
                    style={{"fill": "#f4b183", "fillOpacity": 1, "fillRule": "nonzero", "stroke": "none"}}
                    d="m 354.8968,364.8293 h 63.2194 v -63.2194 h -63.2194 z"/>
                <g
                    transform="matrix(7.874016e-5,0,0,-7.874016e-5,354.8968,364.8293)"
                    id="g30">
                    <path
                        id="path32"
                        style={{
                            "fill": "none",
                            "stroke": "#f4b183",
                            "strokeWidth": 12700,
                            "strokeLinecap": "butt",
                            "strokeLinejoin": "miter",
                            "strokeMiterlimit": 8,
                            "strokeDasharray": "none",
                            "strokeOpacity": 1
                        }}
                        d="M 0,0 H 802887 V 802887 H 0 Z"/>
                </g>
                <path
                    id="path34"
                    style={{"fill": "#f4b183", "fillOpacity": 1, "fillRule": "nonzero", "stroke": "none"}}
                    d="m 418.1161,364.8294 h 63.2195 v -63.2195 h -63.2195 z"/>
                <g
                    transform="matrix(7.874016e-5,0,0,-7.874016e-5,418.1161,364.8294)"
                    id="g36">
                    <path
                        id="path38"
                        style={{
                            "fill": "none",
                            "stroke": "#f4b183",
                            "strokeWidth": 12700,
                            "strokeLinecap": "butt",
                            "strokeLinejoin": "miter",
                            "strokeMiterlimit": 8,
                            "strokeDasharray": "none",
                            "strokeOpacity": 1
                        }}
                        d="M 0,0 H 802887 V 802887 H 0 Z"/>
                </g>
                <path
                    id="path40"
                    style={{"fill": "#f4b183", "fillOpacity": 1, "fillRule": "nonzero", "stroke": "none"}}
                    d="m 481.3363,364.8293 h 63.2194 v -63.2194 h -63.2194 z"/>
                <g
                    transform="matrix(7.874016e-5,0,0,-7.874016e-5,481.3363,364.8293)"
                    id="g42">
                    <path
                        id="path44"
                        style={{
                            "fill": "none",
                            "stroke": "#f4b183",
                            "strokeWidth": 12700,
                            "strokeLinecap": "butt",
                            "strokeLinejoin": "miter",
                            "strokeMiterlimit": 8,
                            "strokeDasharray": "none",
                            "strokeOpacity": 1
                        }}
                        d="M 0,0 H 802887 V 802887 H 0 Z"/>
                </g>
                <path
                    id="path46"
                    style={{"fill": "#f4b183", "fillOpacity": 1, "fillRule": "nonzero", "stroke": "none"}}
                    d="m 418.1172,301.6095 h 63.2194 V 238.39 h -63.2194 z"/>
                <g
                    transform="matrix(7.874016e-5,0,0,-7.874016e-5,418.1172,301.6095)"
                    id="g48">
                    <path
                        id="path50"
                        style={{
                            "fill": "none",
                            "stroke": "#f4b183",
                            "strokeWidth": 12700,
                            "strokeLinecap": "butt",
                            "strokeLinejoin": "miter",
                            "strokeMiterlimit": 8,
                            "strokeDasharray": "none",
                            "strokeOpacity": 1
                        }}
                        d="M 0,0 H 802887 V 802887 H 0 Z"/>
                </g>
                <path
                    id="path52"
                    style={{"fill": "#f4b183", "fillOpacity": 1, "fillRule": "nonzero", "stroke": "none"}}
                    d="M 481.3366,301.6095 H 544.556 V 238.3901 H 481.3366 Z"/>
                <g
                    transform="matrix(7.874016e-5,0,0,-7.874016e-5,481.3366,301.6095)"
                    id="g54">
                    <path
                        id="path56"
                        style={{
                            "fill": "none",
                            "stroke": "#f4b183",
                            "strokeWidth": 12700,
                            "strokeLinecap": "butt",
                            "strokeLinejoin": "miter",
                            "strokeMiterlimit": 8,
                            "strokeDasharray": "none",
                            "strokeOpacity": 1
                        }}
                        d="M 0,0 H 802887 V 802887 H 0 Z"/>
                </g>
                <path
                    id="path58"
                    style={{"fill": "#f4b183", "fillOpacity": 1, "fillRule": "nonzero", "stroke": "none"}}
                    d="m 354.8973,238.3904 h 63.2195 V 175.171 h -63.2195 z"/>
                <g
                    transform="matrix(7.874016e-5,0,0,-7.874016e-5,354.8973,238.3904)"
                    id="g60">
                    <path
                        id="path62"
                        style={{
                            "fill": "none",
                            "stroke": "#f4b183",
                            "strokeWidth": 12700,
                            "strokeLinecap": "butt",
                            "strokeLinejoin": "miter",
                            "strokeMiterlimit": 8,
                            "strokeDasharray": "none",
                            "strokeOpacity": 1
                        }}
                        d="M 0,0 H 802887 V 802887 H 0 Z"/>
                </g>
                <path
                    id="path64"
                    style={{"fill": "#f4b183", "fillOpacity": 1, "fillRule": "nonzero", "stroke": "none"}}
                    d="m 418.117,238.3902 h 63.2194 V 175.1708 H 418.117 Z"/>
                <g
                    transform="matrix(7.874016e-5,0,0,-7.874016e-5,418.117,238.3902)"
                    id="g66">
                    <path
                        id="path68"
                        style={{
                            "fill": "none",
                            "stroke": "#f4b183",
                            "strokeWidth": 12700,
                            "strokeLinecap": "butt",
                            "strokeLinejoin": "miter",
                            "strokeMiterlimit": 8,
                            "strokeDasharray": "none",
                            "strokeOpacity": 1
                        }}
                        d="M 0,0 H 802887 V 802887 H 0 Z"/>
                </g>
                <path
                    id="path70"
                    style={{"fill": "#f4b183", "fillOpacity": 1, "fillRule": "nonzero", "stroke": "none"}}
                    d="m 481.3364,238.3902 h 63.2194 v -63.2195 h -63.2194 z"/>
                <g
                    transform="matrix(7.874016e-5,0,0,-7.874016e-5,481.3364,238.3902)"
                    id="g72">
                    <path
                        id="path74"
                        style={{
                            "fill": "none",
                            "stroke": "#f4b183",
                            "strokeWidth": 12700,
                            "strokeLinecap": "butt",
                            "strokeLinejoin": "miter",
                            "strokeMiterlimit": 8,
                            "strokeDasharray": "none",
                            "strokeOpacity": 1
                        }}
                        d="M 0,0 H 802887 V 802887 H 0 Z"/>
                </g>
                <path
                    id="path76"
                    style={{"fill": "#f4b183", "fillOpacity": 1, "fillRule": "nonzero", "stroke": "none"}}
                    d="m 291.6782,301.6096 h 63.2194 v -63.2194 h -63.2194 z"/>
                <g
                    transform="matrix(7.874016e-5,0,0,-7.874016e-5,291.6782,301.6096)"
                    id="g78">
                    <path
                        id="path80"
                        style={{
                            "fill": "none",
                            "stroke": "#f4b183",
                            "strokeWidth": 12700,
                            "strokeLinecap": "butt",
                            "strokeLinejoin": "miter",
                            "strokeMiterlimit": 8,
                            "strokeDasharray": "none",
                            "strokeOpacity": 1
                        }}
                        d="M 0,0 H 802887 V 802887 H 0 Z"/>
                </g>
                <path
                    id="path82"
                    style={{"fill": "#f4b183", "fillOpacity": 1, "fillRule": "nonzero", "stroke": "none"}}
                    d="m 418.1154,428.0491 h 63.2194 v -63.2195 h -63.2194 z"/>
                <g
                    transform="matrix(7.874016e-5,0,0,-7.874016e-5,418.1154,428.0491)"
                    id="g84">
                    <path
                        id="path86"
                        style={{
                            "fill": "none",
                            "stroke": "#f4b183",
                            "strokeWidth": 12700,
                            "strokeLinecap": "butt",
                            "strokeLinejoin": "miter",
                            "strokeMiterlimit": 8,
                            "strokeDasharray": "none",
                            "strokeOpacity": 1
                        }}
                        d="M 0,0 H 802887 V 802887 H 0 Z"/>
                </g>
                <path
                    id="path88"
                    style={{"fill": "#f4b183", "fillOpacity": 1, "fillRule": "nonzero", "stroke": "none"}}
                    d="m 418.1166,175.171 h 63.2195 v -63.2194 h -63.2195 z"/>
                <g
                    transform="matrix(7.874016e-5,0,0,-7.874016e-5,418.1166,175.171)"
                    id="g90">
                    <path
                        id="path92"
                        style={{
                            "fill": "none",
                            "stroke": "#f4b183",
                            "strokeWidth": 12700,
                            "strokeLinecap": "butt",
                            "strokeLinejoin": "miter",
                            "strokeMiterlimit": 8,
                            "strokeDasharray": "none",
                            "strokeOpacity": 1
                        }}
                        d="M 0,0 H 802887 V 802887 H 0 Z"/>
                </g>
                <path
                    id="path94"
                    style={{"fill": "#f4b183", "fillOpacity": 1, "fillRule": "nonzero", "stroke": "none"}}
                    d="m 544.5558,301.6098 h 63.2195 v -63.2195 h -63.2195 z"/>
                <g
                    transform="matrix(7.874016e-5,0,0,-7.874016e-5,544.5558,301.6098)"
                    id="g96">
                    <path
                        id="path98"
                        style={{
                            "fill": "none",
                            "stroke": "#f4b183",
                            "strokeWidth": 12700,
                            "strokeLinecap": "butt",
                            "strokeLinejoin": "miter",
                            "strokeMiterlimit": 8,
                            "strokeDasharray": "none",
                            "strokeOpacity": 1
                        }}
                        d="M 0,0 H 802887 V 802887 H 0 Z"/>
                </g>
                <path
                    id="path100"
                    style={{"fill": "#f4b183", "fillOpacity": 1, "fillRule": "nonzero", "stroke": "none"}}
                    d="m 607.7743,301.6101 h 63.2194 v -63.2195 h -63.2194 z"/>
                <g
                    transform="matrix(7.874016e-5,0,0,-7.874016e-5,607.7743,301.6101)"
                    id="g102">
                    <path
                        id="path104"
                        style={{
                            "fill": "none",
                            "stroke": "#f4b183",
                            "strokeWidth": 12700,
                            "strokeLinecap": "butt",
                            "strokeLinejoin": "miter",
                            "strokeMiterlimit": 8,
                            "strokeDasharray": "none",
                            "strokeOpacity": 1
                        }}
                        d="M 0,0 H 802887 V 802887 H 0 Z"/>
                </g>
                <path
                    id="path106"
                    style={{"fill": "#f4b183", "fillOpacity": 1, "fillRule": "nonzero", "stroke": "none"}}
                    d="m 607.7724,364.8298 h 63.2195 v -63.2194 h -63.2195 z"/>
                <g
                    transform="matrix(7.874016e-5,0,0,-7.874016e-5,607.7724,364.8298)"
                    id="g108">
                    <path
                        id="path110"
                        style={{
                            "fill": "none",
                            "stroke": "#f4b183",
                            "strokeWidth": 12700,
                            "strokeLinecap": "butt",
                            "strokeLinejoin": "miter",
                            "strokeMiterlimit": 8,
                            "strokeDasharray": "none",
                            "strokeOpacity": 1
                        }}
                        d="M 0,0 H 802887 V 802887 H 0 Z"/>
                </g>
                <path id="path112"
                      style={{"fill": "#f4b183", "fillOpacity": 1, "fillRule": "nonzero", "stroke": "none"}}
                      d="m 607.7753,238.3904 h 63.2195 V 175.171 h -63.2195 z"/>
                <g transform="matrix(7.874016e-5,0,0,-7.874016e-5,607.7753,238.3904)" id="g114">
                    <path id="path116"
                          style={{
                              "fill": "none",
                              "stroke": "#f4b183",
                              "strokeWidth": 12700,
                              "strokeLinecap": "butt",
                              "strokeLinejoin": "miter",
                              "strokeMiterlimit": 8,
                              "strokeDasharray": "none",
                              "strokeOpacity": 1
                          }}
                          d="M 0,0 H 802887 V 802887 H 0 Z"/>
                </g>
            </g>
        </g>
    </svg>