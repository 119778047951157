import axios from 'axios';

const instance = axios.create({
    timeout: 60000
})

// 设置后端 API 的基本 URL
//axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_API_BASE_URL_DEV;


// 设置请求拦截器（如果需要）
instance.interceptors.request.use(
    (config) => {
        // 在此处可以进行请求前的一些处理
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 设置响应拦截器（如果需要）
instance.interceptors.response.use(
    (response) => {
        // 在此处可以进行响应后的一些处理
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 发送 GET 请求
export const get = (url, params) => {
    return instance.get(url, {params})
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};

// 发送 POST 请求
export const post = (url, data) => {
    return instance.post(url, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};
