import React from 'react';
import EngineSection from "./EngineSection";

function EnginePage() {
    return (
        <>
            <EngineSection/>
        </>
    );
}

export default EnginePage;
