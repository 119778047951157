import {post} from '../Api';

export const fetchTwitterAuthRedirectURL = () => {
    return post('/api/auth/twitter/redirectURL');
};

export const twitterLogin = (data) => {
    return post('/api/auth/twitter/login', data);
};

export const logout = () => {
    return post('/api/auth/logout');
}