import React, {useContext, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/UserProvider';
import {twitter_icon} from "../../icon/icon";
import {fetchTwitterAuthRedirectURL, logout} from "../../api/auth/AuthApi";
import {logo_icon} from "../../icon/logo";

function Navbar() {
    const {user, isLoggedIn, logoutUSer} = useContext(UserContext);
    const [showDropdown, setShowDropdown] = useState(false);

    const navigate = useNavigate();

    function redirectToTwitterAuthPage() {
        fetchTwitterAuthRedirectURL()
            .then(response => {
                if (response.success && response.data && response.data.url) {
                    window.location.href = response.data.url
                }
            })
    }

    const handleLogout = () => {
        logout()
            .then(response => {
                logoutUSer();
                navigate('/', {replace: true});
                window.location.reload()
            })
    }

    const isAboutUsPage = window.location.pathname === "/aboutUs";
    const isEnginePage = window.location.pathname === '/' || window.location.pathname === '/engine';

    return (
        <nav className="bg-white shadow-2xl fixed top-0 left-0 right-0 z-50 h-16">
            <div className="flex items-center">
                <Link to="/" className="font-bold text-lg">
                    <div className="flex items-center">
                        {logo_icon("h-16 w-16 pl-1", "#ffffff")}
                        <p className="text-3xl text-gray-700">Pond</p>
                    </div>
                </Link>

                <div className="flex mx-auto">
                    <Link to="/engine"
                          className={`hover:text-gray-900 px-1 mx-2 md:px-4 rounded-lg border-t border-b ${isEnginePage ? 'text-gray-700 shadow-outline-orange ' : 'text-gray-500 shadow-outline-gray '}`}>
                        <div className="flex items-center">
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000"
                                 className="hidden md:block h-6 w-6">
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path opacity=".25"
                                          d="M20.5 5a2.494 2.494 0 0 0-2.03 1.05l-4.53-2.012A2.499 2.499 0 1 0 9 3.499L4.186 7.11A2.403 2.403 0 0 0 3.5 7a2.5 2.5 0 0 0 0 5c.015 0 .028-.004.043-.004l.939 4.226a2.497 2.497 0 1 0 3.345 3.173l3.918.652a5.947 5.947 0 0 1-1.095-1.196l-2.66-.443a2.483 2.483 0 0 0-.406-1.285l2.037-2.037a5.858 5.858 0 0 1 .43-1.843l-3.174 3.173A2.483 2.483 0 0 0 5.5 16c-.015 0-.028.004-.042.004l-.94-4.226a2.509 2.509 0 0 0 1.26-1.26l4.226.94c0 .014-.004.027-.004.042a2.468 2.468 0 0 0 .312 1.192 5.919 5.919 0 0 1 .688-1V11a1 1 0 0 1 1-1h1a.962.962 0 0 1 .246.05 5.847 5.847 0 0 1 .994-.311 2.467 2.467 0 0 0-1.546-.72l-.396-3.162a2.5 2.5 0 0 0 1.231-.908l4.532 2.014a2.302 2.302 0 0 0 .034 1.18l-2.949 1.475c.118-.007.235-.018.354-.018a5.872 5.872 0 0 1 1.51.203l1.533-.766a2.502 2.502 0 0 0 .92.731L19.2 10.91a5.948 5.948 0 0 1 .837.823l.4-1.74c.022.001.042.007.063.007a2.5 2.5 0 0 0 0-5zM5 17h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1zm-1-6H3a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a.974.974 0 0 1-.06.295A.988.988 0 0 1 4 11zm6.222-.518l-4.226-.94c0-.014.004-.027.004-.041l4.817-3.613a2.497 2.497 0 0 0 .49.092l.395 3.163a2.501 2.501 0 0 0-1.48 1.339zM13 4a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V3a.974.974 0 0 1 .06-.295A.988.988 0 0 1 11 2h1a1 1 0 0 1 1 1zm9 4a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1zm-4.007 14l.69.689a2.455 2.455 0 0 1-3.504-1.305c.107.005.213.016.321.016a5.688 5.688 0 0 0 .571-.047A.994.994 0 0 0 17 22z"></path>
                                    <path
                                        d="M9.85 5.363a2.52 2.52 0 1 0-.603-.798L5.151 7.637A2.49 2.49 0 1 0 6 9.5a2.473 2.473 0 0 0-.247-1.065zM10 4V3a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1zM5 9v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1zm15.75 9.862a.668.668 0 0 0-.943 0l-.124.124-.757-.757a4.451 4.451 0 1 0-.689.691l.756.756-.13.129a.668.668 0 0 0 0 .943l2.057 2.055a.666.666 0 0 0 .942 0l.933-.932a.68.68 0 0 0 0-.962zM12.1 15.5a3.4 3.4 0 1 1 3.4 3.4 3.404 3.404 0 0 1-3.4-3.4z"></path>
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                </g>
                            </svg>
                            <p className="font-bold text-lg">SearchEngine</p>
                        </div>
                    </Link>
                    <Link to="/aboutUs"
                          className={`hover:text-gray-900 px-1 mx-2 md:px-4 rounded-lg border-t border-b  ${isAboutUsPage ? 'text-gray-700 shadow-outline-orange ' : 'text-gray-500 shadow-outline-gray '}`}>
                        <div className="flex items-center ">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                 className="hidden md:block h-6 w-6">
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M11.3103 1.77586C11.6966 1.40805 12.3034 1.40805 12.6897 1.77586L20.6897 9.39491L23.1897 11.7759C23.5896 12.1567 23.605 12.7897 23.2241 13.1897C22.8433 13.5896 22.2103 13.605 21.8103 13.2241L21 12.4524V20C21 21.1046 20.1046 22 19 22H14H10H5C3.89543 22 3 21.1046 3 20V12.4524L2.18966 13.2241C1.78972 13.605 1.15675 13.5896 0.775862 13.1897C0.394976 12.7897 0.410414 12.1567 0.810345 11.7759L3.31034 9.39491L11.3103 1.77586ZM5 10.5476V20H9V15C9 13.3431 10.3431 12 12 12C13.6569 12 15 13.3431 15 15V20H19V10.5476L12 3.88095L5 10.5476ZM13 20V15C13 14.4477 12.5523 14 12 14C11.4477 14 11 14.4477 11 15V20H13Z"
                                          fill="#000000"></path>
                                </g>
                            </svg>
                            <p className="font-bold text-lg">About Us</p>
                        </div>
                    </Link>
                </div>
                <div className="flex items-center mr-2">
                    {isLoggedIn() ? (
                        <div className="flex items-center">
                            {twitter_icon("h-5 w-5")}
                            <div className="relative inline-block text-left">
                                <button
                                    onClick={() => setShowDropdown(!showDropdown)}
                                    className="text-xl"
                                >
                                    @{user.twitterHandle}
                                </button>
                                {showDropdown && (
                                    <div
                                        className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-100">
                                        <div className="py-1">
                                            <Link to="/profile">
                                                <button
                                                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                    onClick={() => setShowDropdown(false)}
                                                >
                                                    add wallet
                                                </button>
                                            </Link>
                                            <button
                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                onClick={handleLogout}
                                            >
                                                Logout
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="flex items-center mr-3">
                            {twitter_icon("h-5 w-5")}
                            <button onClick={redirectToTwitterAuthPage} className="text-xl">
                                Login
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
